import useCart from "grandus-lib/hooks/useCart";
import {useEffect, useRef, useState} from "react";
import styles from "./CartButton.module.scss";
import {useTranslation} from "next-i18next";

export const CartPopup = () => {
    const { cart, isLoading } = useCart();
    const {t} = useTranslation()
    const [addedItems, setAddedItems] = useState(0)
    const cartRef= useRef(cart)
    useEffect(() => {
            if (cartRef.current && cart?.pieceCount > cartRef.current?.pieceCount) {
                setAddedItems(cart.pieceCount-cartRef.current.pieceCount)
                setTimeout(()=> setAddedItems(0), 5000)
            }
            cartRef.current = cart
    }, [cart]);

    return (
        <>
            {addedItems ?
                <div className={styles.popup}>
                    {t('Položka pridaná do košíka', {count:addedItems})}
                </div>:""
            }
        </>
    )
}
