export const MenuIcon = (props) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M40 0H0V5.21739H40V0Z" fill="currentColor"/>
            <path d="M40 34.7826H0V40H40V34.7826Z" fill="currentColor"/>
            <path d="M40 17.3912H0V22.6086H40V17.3912Z" fill="currentColor"/>
        </svg>
    )
}
