import React from 'react';
export const LoginIcon = (props) => {
    return (
        <svg width="10"
             height="10"
             viewBox="0 0 10 10"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             {...props}
        >
            <path d="M8.53555 6.46445C7.99096 5.91988 7.34275 5.51674 6.63553 5.2727C7.39299 4.751 7.89062 3.87789 7.89062 2.89062C7.89062 1.29674 6.59389 0 5 0C3.40611 0 2.10938 1.29674 2.10938 2.89062C2.10938 3.87789 2.60701 4.751 3.36449 5.2727C2.65727 5.51674 2.00906 5.91988 1.46447 6.46445C0.520098 7.40885 0 8.66445 0 10H0.78125C0.78125 7.67377 2.67377 5.78125 5 5.78125C7.32623 5.78125 9.21875 7.67377 9.21875 10H10C10 8.66445 9.4799 7.40885 8.53555 6.46445ZM5 5C3.83689 5 2.89062 4.05375 2.89062 2.89062C2.89062 1.7275 3.83689 0.78125 5 0.78125C6.16311 0.78125 7.10938 1.7275 7.10938 2.89062C7.10938 4.05375 6.16311 5 5 5Z" fill="currentColor"/>
        </svg>
    )
}
