import useCart from "grandus-lib/hooks/useCart";
import { get } from "lodash";
import Link from "next/link";

import styles from "./CartButton.module.scss";
import {useTranslation} from "next-i18next";
import {CartPopup} from "components/cart/CartPopup";

const CartButton = () => {
  const { cart, isLoading } = useCart();
  const {t} = useTranslation()

  const pieceCount = get(cart, "pieceCount", 0);

  return (
    <Link href="/kosik" as={`/kosik`}>
      <a className={styles.cart}>
        {
          pieceCount ? (
            <div className={styles.badge}>
              {pieceCount}
            </div>
          ) : ""
        }
        <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2.15H5.69C7.13 2.15 8.36 3.17 8.64 4.58L11.15 17.57C11.42 18.98 12.66 20 14.1 20H32.09C33.53 20 34.76 18.98 35.04 17.57L37.33 5.72C37.69 3.87 36.27 2.15 34.38 2.15L13.83 2" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <circle cx="16" cy="29" r="3" fill="currentColor"/>
          <circle cx="32" cy="29" r="3" fill="currentColor"/>
        </svg>
        {t('Košík')}
        <CartPopup/>
      </a>
    </Link>
  );
};

export default CartButton;
