// import "scripts/wdyr";
import "../styles/custom-antd.css";
import "../styles/global.scss";
import "slick-carousel/slick/slick.css";

import { useEffect } from "react";
import { appWithTranslation } from "next-i18next";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import Router, {useRouter} from "next/router";
import TagManager from "grandus-lib/utils/gtag";
import FBPixel from "grandus-lib/utils/fbpixel";

import InfoBox from "components/InfoBox";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

import NProgress from "nprogress";
import dayjs from "dayjs";
import Cookies from "js-cookie";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// export function reportWebVitals(metric) {
//   if (metric.label === "custom") {
//     console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
//   }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const GrandusApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const {locale, defaultLocale} = router;
  const isSrandon = router?.asPath == "/kategoria/srandon"

  useEffect(() => {
    TagManager.registerPageViewTracking(Router);
    FBPixel.registerPageViewTracking(Router);

    const nextLocale = Cookies.get('NEXT_LOCALE');
    if (nextLocale && nextLocale !== locale) {
      dayjs.locale(locale);
      if (nextLocale === defaultLocale) {
        window.location.href = `/`
      } else {
        window.location.href = `/${nextLocale}`
      }
    }
  }, []);

  return (
    <>
      <InfoBox />
      <Header isSrandon={isSrandon} />
      <Component {...pageProps} />
      <Footer isSrandon={isSrandon} />
      {/* <CookieConsent /> */}
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default appWithTranslation(GrandusApp);
