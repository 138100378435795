import * as yup from "yup";
import { Form, Button, Row, Col, message, Checkbox } from "antd";
import { useFormikContext, Formik } from "formik";
import { get } from "lodash";

import TextInput from "grandus-lib/components-atomic/form/TextInput";

import styles from "components/forms/Newsletter/Newsletter.module.scss";
import {useTranslation} from "next-i18next";
import TagManager from "grandus-lib/utils/gtag";
import EnhancedEcommerce from "grandus-lib/utils/ecommerce";

const Newsletter = () => {
  const { t } = useTranslation();

  const formProps = {
    enableReinitialize: true,
    initialValues: {
      email: "",
      privacyPolicy: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .nullable()
        .email(t("Zadajte email vo formáte name@domain.com"))
        .required(t("Zadajte vašu emailovú adresu")),
      privacyPolicy: yup
        .bool()
        .oneOf([true], t("Musíte súhlasiť so spracovaním osobných údajov")),
    }),
    onSubmit: async (values, { setFieldError, setErrors, resetForm }) => {
      const { email } = values;
      const reqBody = { email: email };
      try {
        const response = await fetch(`/api/lib/v1/newsletter/subscribe`, {
          method: "POST",
          body: JSON.stringify(reqBody),
        }).then((data) => data.json());

        if (response) {
          const errorMessage = get(get(response, "messages.0"), "message");
          if (!errorMessage) {
            resetForm();
            TagManager.push(
              EnhancedEcommerce.form_submit({id: "form-newsletter"})
            );
            message.success(
              t("Boli ste úspešne prihlásený na odber newslettra"),
              5
            );
          } else {
            setFieldError("email", errorMessage);
          }
        }
      } catch (error) {
        console.error("An unexpected error happened:", error);
        setErrors(error.data.message);
      }

      return false;
    },
  };

  return (
      <div className={"container"}>
        <Row align="middle" justify="space-between" gutter={20} className={styles?.newsletter}>
          <Col xs={24} lg={12}>
            <h5>{t("Zostaňte v kontakte s trnavským regiónom")}</h5>
            <p>
              {t("Zanechajte nám váš e-mail aby vám neunikla žiadna novinka z nášho regiónu")}
            </p>
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <Formik {...formProps}>
              <NewsletterForm />
            </Formik>
          </Col>
        </Row>
      </div>
  );
};

const NewsletterForm = () => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();
  return (
    <Form wrapperCol={{ span: 24 }} layout="horizontal" onFinish={handleSubmit} id={"form-newsletter"}>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24}>
          <TextInput
            label={t("Email")}
            name="email"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col xs={24} lg={18}>
          <Form.Item
            valuePropName="checked"
            className={styles?.agreementCheckboxWrapper}
            name="privacyPolicy"
            validateStatus={
              touched?.privacyPolicy && errors?.privacyPolicy ? "error" : ""
            }
          >
            <Checkbox onChange={handleChange} className={`checkbox-podujatia`}>
              {t("Súhlasím so spracovaním osobných údajov")}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <div className={styles.submitWrapper}>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={isValid && isSubmitting}
                disabled={!dirty || !isValid}
                block
                className={`btn btn-dark`}
              >
                {t("Odoslať")}
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default Newsletter;
