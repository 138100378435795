import { useTranslation } from "next-i18next";
import useUser from "grandus-lib/hooks/useUser";
import { get } from "lodash";
import Link from "next/link";
import useWishlist from "grandus-lib/hooks/useWishlist";
import styles from "./AuthButton.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import UserMenu from "components/user/UserMenu";

const AuthButton = () => {
  const { t } = useTranslation();
  const { wishlist, isLoading: isLoadingWishlist } = useWishlist();
  const { user } = useUser();

  const wislistCount = wishlist?.productIds.length;

  if (user) {
    return (
      <div className={styles.authLogged}>
        <div className={styles.user}>
          <Dropdown overlay={<UserMenu />}>
            <span className={styles.loggedUser}>
              {get(user, "credit", false) ? (
                <span className={styles.credits}>
                  {get(user, "credit")} {t("bodov")}
                </span>
              ) : (
                ""
              )}
              {get(user, "email")}{" "}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10L10 13L13 10"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Dropdown>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.authNotLogged}>
    </div>
  );
};

export default AuthButton;
