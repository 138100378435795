import useSWR from "swr";
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import Link from "next/link";

import { HEADER_MENU_CATGEORY } from "constants/CustomConstants";

import styles from "./Menu.module.scss";

const Submenu = ({ categories = [] }) => {
  if (isEmpty(categories)) {
    return null;
  }

  return (
    <ul className={styles?.submenu}>
      {map(categories, (category) => (
        <li key={`header-category-${category.id}`}>
          {get(category, "externalUrl") ? (
            <a href={get(category, "externalUrl")}>{category.name}</a>
          ) : (
            <Link href="/kategoria/[id]" as={`/kategoria/${category.urlName}`}>
              <a>
                {category.name}
              </a>
            </Link>
          )}
          {!isEmpty(category.children) ? (
            <Submenu categories={category.children} />
          ) : null}
        </li>
      ))}
    </ul>
  );
};

const Menu = () => {
  const { data, error } = useSWR("/api/lib/v1/categories", (url) =>
    fetch(url).then((r) => r.json())
  );

  if (isEmpty(data)) {
    return null;
  }

  const categories = filter(data, { hash: HEADER_MENU_CATGEORY });

  return (
    <>
      {map(categories, (category) => (
        <li key={`header-category-${category.id}`} className={styles?.menuItem}>
          {get(category, "externalUrl") ? (
            <a href={get(category, "externalUrl")}>{category.name}</a>
          ) : (
            <Link href="/kategoria/[id]" as={`/kategoria/${category.urlName}`}>
              <a className={styles?.menuItem}>
                {category.name}
              </a>
            </Link>
          )}
          {!isEmpty(category.children) ? (
            <Submenu categories={category.children} />
          ) : null}
        </li>
      ))}
    </>
  );
};

export default Menu;
