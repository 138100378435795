import AuthButton from "components/user/AuthButton";
import AuthButtonMobile from "components/user/AuthButtonMobile";
import { get } from "lodash";
import CartButton from "components/cart/CartButton";
import WishlistButton from "components/header/WishlistButton";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";

import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import HeaderMenu from "./Menu.js";

import startsWith from "lodash/startsWith";

import styles from "./Header.module.scss";
import Transition from "pages/_transition";
import { useRouter } from "next/router";
import LanguageSwitch from "./LanguageSwitch";
import useWebInstance from "grandus-lib/hooks/useWebInstance";
import {useTranslation} from "next-i18next";
import {LoginIcon} from "components/_other/icons/LoginIcon";
import {MenuIcon} from "components/_other/icons/MenuIcon";

const Header = ({isSrandon}) => {
  const router = useRouter();
  const data = useWebInstance()
  const { t } = useTranslation();

  const [openedMenu, setOpenedMenu] = useState(false);

  const webinstance = get(data, "webInstance", {});
  const logoPath =
    get(webinstance, "logo.path") + "/" + get(webinstance, "logo.id");
  const logo = {
    ...get(webinstance, "logo", {}),
    path: logoPath,
  };

  return (
    <header id={styles.header} className={isSrandon ? styles.srandonHeader : ""}>
      <div className="container">
        <div className={styles.topBar}>
          <Transition>
            <Link href="/" as={`/`}>
              <a className={styles.logoWrapper}>
                <Image
                    photo={logo}
                    size={"106x41"}
                    type={"png"}
                    className={styles.logo}
                    imageProps={{
                      width: 106,
                      height: 41,
                    }}
                />
              </a>
            </Link>
            <div
              className={`${styles.menuWrapper} ${
                openedMenu ? styles.open : ""
              }`}
            >
              <Link href="/" as={`/`}>
                <a>
                  <Image
                    photo={logo}
                    size={"106x41"}
                    type={"png"}
                    className={styles.logo}
                    imageProps={{
                      width: 106,
                      height: 41,
                    }}
                  />
                </a>
              </Link>
              <a
                className={styles.closeButton}
                onClick={() => setOpenedMenu(!openedMenu)}
              >
                <CloseOutlined style={{ fontSize: "1.5em" }} />
              </a>
              <ul className={styles.menu}>
                <HeaderMenu />
                {get(webinstance, "pagesHeader", []).map((page, index) => {
                  return (
                    <li key={`top-bar-static-page-${page.id}-${index}`}>
                      {get(page, "externalUrl") ? (
                        <a
                          href={get(page, "externalUrl")}
                          className={styles?.firstLevel}
                        >
                          {page.title}
                        </a>
                      ) : (
                        <Link
                          href="/stranka/[id]"
                          as={`/stranka/${page.urlTitle}`}
                        >
                          <a
                            onClick={() => setOpenedMenu(false)}
                            className={styles?.firstLevel}
                          >
                            {page.title}
                          </a>
                        </Link>
                      )}
                    </li>
                  );
                })}
                <LanguageSwitch />
                <li className={styles?.actionButtons}>
                  <Link href="/prihlasenie">
                    <a>
                      <LoginIcon/>
                      {t("Prihlásenie")}
                    </a>
                  </Link>

                  <Link href="/registracia" as={`/registracia`}>
                    {t("Registrácia")}
                  </Link>

                  <CartButton />
                  <WishlistButton />
                  <AuthButton />
                </li>
              </ul>
            </div>
          </Transition>
          <div className={styles.iconsWrapper}>
            <a
              className={styles.mobileMenu}
              onClick={() => setOpenedMenu(!openedMenu)}
            >
              {openedMenu ? (
                <CloseOutlined style={{ fontSize: "2.3em" }} />
              ) : (
                <MenuIcon/>
              )}
            </a>
            <div className={styles?.actionButtons}>
              <Link href="/prihlasenie">
                <a>
                  <LoginIcon/>
                  {t("Prihlásenie")}
                </a>
              </Link>

              <Link href="/registracia" as={`/registracia`}>
                {t("Registrácia")}
              </Link>

             <div className={styles.linksDivider}>
               |
             </div>
              <CartButton />
              <WishlistButton />
              <AuthButton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
